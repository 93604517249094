import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import './BusinessSlider.scss';

function BusinessSlider({ data, imageClass, settings }) {
  return (
    <div className="business-slider-slick ml-[5%] md:ml-0 md:mr-[-19px]">
      <Slider {...settings}>
        {data.map((d) => (
          <div
            className="w-full md:w-1/3 px-[12px] md:p-[19px] !flex h-full flex-col"
            key={d.id}>
            <div
              className="w-full h-36 md:h-56 flex items-center justify-center flex-shrink-0 rounded-t"
              style={{ background: d.background }}>
              <img
                {...d.image}
                alt=""
                className={`w-full relative max-w-[130px] md:max-w-[200px] ${imageClass}`}
              />
            </div>
            <div className="text-2.5sm md:text-base p-[16px] md:p-[24px] bg-white flex-1 rounded-b flex flex-col justify-between">
              {d.text}
              {d.focus?.length > 0 && (
                <div className="mt-6">
                  <div className="font-semibold md:font-normal text-tiny md:text-base">
                    Use case in focus:
                  </div>
                  <ul className="mt-[8px]">
                    {d.focus.map(({ text, id }) => (
                      <li
                        key={id}
                        className="px-[4px] md:px-[16px] py-[8px] md:py-[10px] flex items-center bg-cf-light-grey rounded md:max-w-max mb-[8px] last:mb-0">
                        <span className="w-[20px] flex-shrink-0">
                          <img
                            src="/img/check.svg"
                            width="20"
                            height="21"
                            alt=""
                          />
                        </span>
                        <div className="flex-1 pl-[8px] text-vtiny md:text-2.5sm text-cf-hero font-semibold">
                          {text}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

BusinessSlider.propTypes = {
  imageClass: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      image: PropTypes.shape({}),
      background: PropTypes.string,
      focus: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
      ),
    }),
  ),
  settings: PropTypes.shape({}),
};

BusinessSlider.defaultProps = {
  data: [],
  imageClass: '',
  settings: {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          // centerMode: false,
          slidesToShow: 1.3,
          infinite: false,
          arrows: true,
          dots: true,
        },
      },
    ],
  },
};

export default BusinessSlider;

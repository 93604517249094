import React from 'react';
import CountUp from '../../CountUp/CountUp';

const stats = [
  {
    id: 0,
    countUpProps: {
      value: 300000,
      suffix: '+',
      useIndianFormat: true,
    },
    desc: 'Merchants',
  },
  {
    id: 1,
    countUpProps: {
      value: 40,
      prefix: 'USD',
      suffix: 'B+',
    },

    desc: 'Processed annually',
  },
  {
    id: 2,
    countUpProps: {
      value: 200,
      suffix: 'M+',
    },
    desc: 'Bank accounts served',
  },
];

const StatsSection = () => {
  return (
    <section className="p-section-2 relative payments-partner text-center overflow-hidden">
      <div className="container">
        <div className="text-shs md:text-shl font-semibold mb-5 font-heading ">
          Your Payments Partner
        </div>
        <div className="leading-[24px] mb-8 md:mb-16 mx-auto ">
          India&apos;s 1<sup>st</sup> Fintech platform to enable disbursal of
          loans and insurance claim settlements
        </div>
        <div className="flex flex-wrap mx-[-21px]  md:-mx-3.5">
          {stats.map((i) => (
            <div key={i.id} className="px-3.5 w-1/3">
              <div className="bg-[#ffffff14] h-full rounded border border-[#ffffff23] flex flex-col justify-start">
                <div className="text-base md:text-[42px] md:leading-[48px] text-cf-primary font-bold md:font-semibold text-center">
                  <CountUp
                    {...i.countUpProps}
                    duration={4}
                    ease="circ.out"
                    alwaysCenter={false}
                  />
                </div>
                <div className="leading-4 md:leading-6 md:mt-2 text-tiny md:text-base text-center">
                  {i.desc}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatsSection;

import React, { useRef } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs/Tabs';
import GetStarted from '../components/GetStarted';
import StatsSection from '../components/Sections/StatsSection/StatsSection';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import BusinessSlider from '../components/Sliders/BusinessSlider/BusinessSlider';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import {
  heroLogoData,
  heroSliderSettings,
  instutionsTabData,
  onlineCoursesTabData,
  businessCards,
} from '../content/education';
import '../styles/education.scss';

function EducationPage({ ...rest }) {
  const container = useRef();

  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=Solutions%20Education&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Solutions%20Education&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Education Payments Platform - Collect School Fees Online, Disburse Expenses"
        description="Built for - schools, colleges, edtech & ERPs. Collect tuition fees, donations, online course fees, & do payouts to teachers, service providers & vendors."
        url="https://www.cashfree.com/solutions/education/"
        keywords={[
          'online fee payment',
          'online school fees payment system',
          'online school fees payment',
          'fee payment',
          'online payment',
          'education ERP',
          'education ERP software',
          'school management software',
          'school fee management software',
          'online fee collection',
          'Education ERP payments',
          'school payment gateway',
          'college payment gateway',
          'university payment gateway',
          'education institute payment gateway',
        ]}
      />

      <section className="relative section-padding bg-cf-primary education-hero overflow-hidden text-white">
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row lg:flex-nowrap lg:items-center w-full lg:mb-[20px] lg:w-[127%]">
            <div className="relative z-[1] lg:w-[45%]">
              <p className="text-sm font-semibold pl-[calc(1.25rem_-_3.5px)] mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[2px] before:md:left-[1.5px] mx-auto inline-block">
                For education businesses
              </p>
              <div className="mb-5 text-xl md:text-2xl font-semibold max-w-[670px] font-heading">
                Complete <h1 className="inline">Education Payments Solution</h1>{' '}
                for India
              </div>
              <p className="text-opacity-80 mb-[30px] max-w-[620px] text-[16px] leading-[24px] md:text-2.5md">
                The fastest and easiest way to collect fees, disburse expenses
                and enable other digital&nbsp;payments.
              </p>
              <a
                className="button button-green w-full md:w-auto mb-[48px] lg:mb-0 md:mr-[14px]"
                href="https://merchant.cashfree.com/merchants/signup?source-action=Solutions%20Education&action=Sign%20Up&button-id=StartNow_Hero">
                Create Account <span className="chevron" />
              </a>
            </div>
            <div className="w-full lg:w-1/2 hidden justify-center h-full items-center lg:flex">
              <TransparentVideo
                backgroundRef={container}
                className="relative z-10"
                poster="/img/education/poster.png"
                width="1496"
                height="1100"
                mp4Src="/img/education/hero-video.mp4"
                webmSrc="/img/education/hero-video.webm"
              />
            </div>
            <div className="w-full lg:w-1/2 lg:pl-[19px] flex justify-center h-full items-center  lg:hidden">
              <TransparentVideo
                backgroundRef={container}
                className="relative z-10"
                poster="/img/education/poster-mb.png"
                width="1496"
                height="1100"
                mp4Src="/img/education/hero-video-mb.mp4"
                webmSrc="/img/education/hero-video-mb.webm"
              />
            </div>
          </div>
        </div>
        <section className="logo-container mt-14 md:mt-[84px]">
          <div className="container">
            <div className="relative overflow-hidden z-10 max-w-[1000px] mx-auto">
              <HeroLogoSlider
                data={heroLogoData}
                imgUrl="/img/education/logos"
                itemClassName="mx-[25px] md:mx-[36px] lg:mx-auto flex items-center align-center h-[50px]"
                settings={heroSliderSettings}
              />
            </div>
          </div>
        </section>
      </section>
      <StatsSection />
      <section className="relative p-section-2 overflow-hidden bg-cf-light-grey">
        <div className="container tab-grid-panel">
          <div className="text-shs md:text-shl font-semibold text-cf-hero mb-8 md:mb-6md font-heading">
            For Schools / Colleges / Coaching&nbsp;Institutions
          </div>
          <Tabs
            timerAutoPlay
            data={instutionsTabData}
            dropDown
            dropDownFontSize="text-[16px]"
          />
        </div>
      </section>
      <section className="relative p-section-2 overflow-hidden ">
        <div className="container tab-grid-panel">
          <div className="text-shs md:text-shl font-semibold text-cf-hero mb-8 md:mb-6md font-heading">
            For Ed-Tech Platforms or Open Online Courses
          </div>
          <Tabs
            timerAutoPlay
            data={onlineCoursesTabData}
            dropDown
            dropDownFontSize="text-[16px]"
          />
        </div>
      </section>
      <section className="bg-cf-primary relative overflow-hidden p-section-2 education-esp">
        <div className="container">
          <div className="flex flex-wrap flex-col-reverse md:flex-row md:items-center relative z-[1]">
            <div className="w-full md:w-5/12">
              <img
                src="/img/education/educational-institute.png"
                alt="Educational ERPs"
                width="878"
                height="808"
                loading="lazy"
              />
            </div>
            <div className="w-full md:w-7/12 md:px-[8.33%]">
              <h2 className="text-shs md:text-shl font-semibold text-white mb-3 md:mb-5 font-heading">
                Educational ERPs
              </h2>
              <div className="text-cf-light-cold-purple text-2.5sm md:text-base">
                Explore the wide range of Cashfree collection and disbursal
                payment solutions and offer your users the best payment
                experience. 
                <br />
                <br />
                Join some of India’s{' '}
                <h2 className="inline font-body">leading education ERP</h2>s
                taking education to newer heights. Talk to our{' '}
                <h2 className="inline font-body">education ERP payment</h2>{' '}
                expert today.
              </div>
              <a
                className="button button-green w-full md:w-auto mt-8 mb-12 md:mb-0"
                href="https://www.cashfree.com/contact-sales?source-action=Solutions%20Education&action=Contact%20Sales&button-id=ContactSales_EducationalERPs">
                Get in Touch Now <span className="chevron" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="relative overflow-hidden bg-cf-light-grey p-section-2">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold text-cf-hero mb-8 md:mb-11 font-heading text-center max-w-[610px] mx-auto">
            Here is why hundreds of education businesses trust us
          </div>
          <div className="flex justify-center pb-[48px] md:pb-0">
            <div className="w-full lg:w-2/3">
              <BusinessSlider data={businessCards} />
            </div>
          </div>
        </div>
      </section>
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Solutions%20Education&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=Solutions%20Education&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default EducationPage;

import React from 'react';
import PropTypes from 'prop-types';

const TabGridPanel = ({ title, text, href, image, cards, type }) => (
  <div className="md:mt-16 text-cf-hero">
    <div
      className={`flex flex-wrap md:mx-[-19px] flex-col-reverse ${
        type === 'start' ? 'md:flex-row' : 'md:flex-row-reverse'
      }`}>
      <div className="w-full md:w-1/2 md:px-[19px] pt-7 md:py-0">
        <img
          className="flex-shrink-0"
          loading="lazy"
          src={`/img/education/${image}`}
          width="1220"
          height="1018"
          alt={title}
        />
      </div>
      <div className="w-full md:w-1/2 md:px-[19px]">
        <div className="hidden md:block text-4md font-semibold pt-6md">
          {title}
        </div>
        <div className="text-2.5sm md:text-base md:mt-3">{text}</div>
        <div className="flex flex-wrap md:mx-[-19px] mt-2md">
          {cards.map((card) => (
            <div
              className="pt-4md md:pt-6md md:px-[19px] w-full md:w-1/2"
              key={card.id}>
              <div className="flex">
                <img
                  src={`/img/bicons/${card.icon}`}
                  alt={card.title}
                  className="w-[32px] h-[32px] flex-shrink-0"
                />
                <div className="pl-2md">
                  <div className="text-2.5sm mb-2md font-semibold text-cf-hero">
                    {card.title}
                  </div>
                  <div className="text-cf-hero text-2.5sm">{card.text}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <a
          className="button button-green w-full md:w-auto mt-[32px] md:mt-[40px]"
          href={href}>
          Create Account <span className="chevron" />
        </a>
      </div>
    </div>
  </div>
);
TabGridPanel.defaultProps = {
  type: 'start',
  text: '',
};
TabGridPanel.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  href: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['start', 'end']),
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      icon: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
};
export default TabGridPanel;

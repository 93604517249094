import React from 'react';
import TabGridPanel from '../components/Tabs/TabGridPanel';

export const heroLogoData = [
  {
    id: 0,
    src: 'byjus.png',
    width: '324',
    height: '93',
    className: 'max-w-[108px]',
    alt: "Byju's",
  },
  {
    id: 1,
    src: 'greatlearning.png',
    width: '255',
    height: '112',
    className: 'max-w-[85px]',
    alt: 'Great Learning',
  },
  {
    id: 2,
    src: 'attainu.png',
    width: '255',
    height: '95',
    className: 'max-w-[85px]',
    alt: 'Attainu',
  },
  // {
  //   id: 3,
  //   src: 'tsus.png',
  //   width: '126',
  //   height: '126',
  //   className: 'max-w-[42px]',
  //   alt: 'tsus',
  // },
  {
    id: 4,
    src: 'kalyanischool.png',
    width: '165',
    height: '118',
    className: 'max-w-[53px]',
    alt: 'The Kalyani School',
  },
  {
    id: 5,
    src: 'shrieducare.png',
    width: '318',
    height: '114',
    className: 'max-w-[105px]',
    alt: 'Shri Educare',
  },
];

export const heroSliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 0,
  swipe: false,
  pauseOnHover: false,
  draggable: false,
  centerMode: false,
  speed: 2000,
  slidesToShow: heroLogoData.length,

  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        rtl: true,
        draggable: false,
        autoPlay: true,
        autoplay: true,
        speed: 2000,
        swipe: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        slidesToShow: 1.8,
        infinite: true,
        centerMode: true,
        variableWidth: true,
      },
    },
  ],
};

export const instutions = [
  {
    id: 0,
    title: 'Collections',
    text: (
      <>
        <h2 className="inline font-body">Collect course fees</h2>, tuition fees,
        examination fees, donations, service fees, platform subscription fees
        and more.
      </>
    ),
    href:
      'https://merchant.cashfree.com/merchants/signup?source-action=Solutions%20Education&action=Sign%20Up&button-id=StartNow_ForSchoolsCollections',
    image: 'p1-1.png',
    cards: [
      {
        id: 0,
        icon: 'payment-links.svg',
        title: 'Payment Links',
        text:
          'Collect payments from students/parents in real time via Whatsapp, Facebook, Instagram, and SMS - no app or website needed!',
      },
      {
        id: 1,
        icon: 'payment-gateway.svg',
        title: 'Payment Gateway',
        text:
          'Collect payment on your website/app with 120+ payment options and card saving feature.',
      },
      {
        id: 2,
        icon: 'auto-collect.svg',
        title: 'Auto Collect',
        text:
          'Create unique virtual bank accounts and UPI IDs for each student and reconcile collections instantly.',
      },
      {
        id: 3,
        icon: 'easy-split.svg',
        title: 'Easy Split',
        text:
          'Split incoming payments with your accounts of different departments and manage funds easily.',
      },
    ],
  },
  {
    id: 1,
    title: 'Verification & Disbursals',
    text:
      'Verify staff or service providers’ account details during onboarding for a background check and enable seamless future disbursals for salaries, service fees and more.',
    href:
      'https://merchant.cashfree.com/merchants/signup?source-action=Solutions%20Education&action=Sign%20Up&button-id=StartNow_ForSchoolsVerification&Disbursals',
    image: 'p1-2.png',
    cards: [
      {
        id: 0,
        icon: 'bav.svg',
        title: 'Bank Account / UPI ID Verification',
        text:
          'Validate the bank account number, IFSC code and UPI ID provided by your staff with the actual bank records & UPI servers.',
      },
      {
        id: 1,
        icon: 'pan-verification.svg',
        title: 'PAN Verification',
        text: (
          <>
            Validate PAN number provided by your staff/service providers with
            the latest PAN&nbsp;database.
          </>
        ),
      },
      {
        id: 2,
        icon: 'aadhaar-verification.svg',
        title: 'Aadhaar Verification',
        text:
          'Validate Aadhaar information provided by your staff/service providers with the centralized Aadhaar Data Vault.',
      },
      {
        id: 3,
        icon: 'payouts.svg',
        title: 'Payouts',
        text:
          'Add multiple sources of funds with payouts and make disbursals to staff or service providers bank accounts, UPI IDs, wallets instantly 24x7 even on a bank holiday.',
      },
    ],
  },
];
export const onlineCourses = [
  {
    id: 0,
    title: 'Collections',
    text:
      'Collect course fees or platform subscription fees, manage fees collected on a course level and more on your website or app.',
    href:
      'https://merchant.cashfree.com/merchants/signup?source-action=Solutions%20Education&action=Sign%20Up&button-id=StartNow_ForEdTechCollections',
    image: 'p2-1.png',
    cards: [
      {
        id: 0,
        icon: 'payment-gateway.svg',
        title: 'Payment Gateway',
        text:
          'Collect payment on your website/app with 120+ payment options and card saving feature.',
      },
      {
        id: 1,
        icon: 'auto-collect.svg',
        title: 'Auto Collect',
        text:
          'Create unique virtual bank accounts and UPI IDs for each of your courses and track payments on a course level.',
      },
      {
        id: 2,
        icon: 'subscriptions.svg',
        title: 'Subscriptions',
        text: (
          <>
            Automate recurring payment collections for course fees, platform
            fees, etc with <span className="whitespace-nowrap">e-mandates</span>{' '}
            for cards, net banking and UPI.
          </>
        ),
      },

      {
        id: 3,
        icon: 'payment-forms.svg',
        title: 'Payment Forms',
        text:
          'Add Payment Forms on website or app, collect fee, and automate payment reconciliation.',
      },
    ],
  },
  {
    id: 1,
    title: 'Verification & Disbursals',
    text:
      'Verify staff or service providers’ account details during onboarding for a background check and enable seamless future disbursals for salaries, service fees and more.',
    image: 'p2-2.png',
    href:
      'https://merchant.cashfree.com/merchants/signup?source-action=Solutions%20Education&action=Sign%20Up&button-id=StartNow_ForEdTechVerification&Disbursals',
    cards: [
      {
        id: 0,
        icon: 'bav.svg',
        title: 'Bank Account / UPI ID Verification',
        text:
          'Validate the bank account number, IFSC code and UPI ID provided by your educator or course owner with the actual bank records & UPI servers.',
      },
      {
        id: 1,
        icon: 'pan-verification.svg',
        title: 'PAN Verification',
        text: (
          <>
            Validate PAN number provided by your educator or course owner with
            the latest PAN&nbsp;database.
          </>
        ),
      },
      {
        id: 2,
        icon: 'aadhaar-verification.svg',
        title: 'Aadhaar Verification',
        text:
          'Validate Aadhaar information provided by your educator or course owner with the centralized Aadhaar Data Vault.',
      },
      {
        id: 4,
        icon: 'payouts.svg',
        title: 'Payouts',
        text:
          'Add multiple sources of funds with payouts and make disbursals to educators or course owners’ bank accounts, UPI IDs, wallets instantly 24x7 even on a bank holiday.',
      },
    ],
  },
];

export const instutionsTabData = instutions.map((d) => ({
  key: d.id,
  heading: d.title,
  content: <TabGridPanel {...d} type="start" />,
}));
export const onlineCoursesTabData = onlineCourses.map((d) => ({
  key: d.id,
  heading: d.title,
  content: <TabGridPanel {...d} type="end" />,
}));

export const businessCards = [
  {
    id: 0,
    image: {
      src: '/img/education/shrieducare.png',
      width: '195',
      height: '70',
    },
    background: '#00226E',
    text:
      "Cashfree is really doing a good job. I'm glad we decided to work with Cashfree. It's really great, we integrated our product with Cashfree and the functionality is a lot better than any other PG services. think Cashfree is probably one of the strongest.",
    focus: [
      {
        id: 0,
        text: 'Online & Offline Fees Collection',
      },
    ],
  },
  {
    id: 0,
    image: {
      src: '/img/education/uniquecreations.png',
      width: '206',
      height: '82',
    },
    background: '#F57314',
    text: (
      <>
        Cashfree helped us with quick support and we were able to set up in no
        time. For educational providers like us, it is one of the best options
        when it comes to flexibility and cost reduction. I am happy to have come
        across such a service. Kudos to the team!
      </>
    ),
    focus: [
      {
        id: 0,
        text: 'Online Fees Collection',
      },
    ],
  },
];
